import { Badge } from "evergreen-ui";
import React from "react";

export const PaymentBlock = (props) => (
  <Badge
    {...props}
    color={
      props.paymentStatus === "unpaid"
        ? "red"
        : props.paymentStatus === "partial_payment"
        ? "orange"
        : props.paymentStatus === "paid"
        ? "green"
        : props.paymentStatus === "payment_failed"
        ? "purple"
        : "neutral"
    }
  >
    {props.paymentStatusText}
  </Badge>
);

export const StatusBlock = (props) => (
  <Badge
    {...props}
    color={
      props.status === "placed"
        ? "red"
        : props.status === "confirmed"
        ? "orange"
        : props.status === "processed"
        ? "yellow"
        : props.status === "delivered"
        ? "teal"
        : props.status === "completed"
        ? "green"
        : props.status === "cancelled"
        ? "neutral"
        : props.status === "returning"
        ? "teal"
        : props.status === "returned"
        ? "purple"
        : "neutral"
    }
  >
    {props.statusText}
  </Badge>
);

export const TypeBlock = (props) => (
  <Badge
    {...props}
    color={
      props.orderType === "online"
        ? "blue"
        : props.orderType === "glovo"
        ? "yellow"
        : props.orderType === "phone"
        ? "blue"
        : props.orderType === "email"
        ? "blue"
        : props.orderType === "fizic"
        ? "purple"
        : "neutral"
    }
  >
    {props.orderTypeText}
  </Badge>
);

export const PreorderBlock = (props) => {
  return props.isPreorder === 1 ? <Badge color="purple">P</Badge> : null;
};

export const SinglesBlock = (props) => {
  let singles = false;

  for (let i in props.items) {
    if (props.items[i].type === "card") {
      singles = true;
      break;
    }
  }

  return singles ? <Badge color="yellow">S</Badge> : null;
};

export const AlertBlock = (props) => {
  if (
    props.email === "faletifloreaionutvaletin@gmail.com" ||
    props.billingPhoneNumber === "0727752015" ||
    props.email === "cristidreghici1234@gmail.com" ||
    props.billingPhoneNumber === "0748428715" ||
    props.email === "donotstory@gmail.com" ||
    props.billingPhoneNumber === "0774506329"
  ) {
    return <Badge color="black">Troll</Badge>;
  }

  return null;
};

export const CompanyBlock = (props) => {
  if(props.companyName) {
    return <Badge color="red">B2B</Badge>
  }
}

export const StorePickupBlock = (props) => {
  if (props.delivery === "personal") {
    return <Badge color="green">M</Badge>;
  }

  return null;
};

export const EntityBlock = (props) => (
  <Badge
    {...props}
    colors={
      props.entityType === "pf"
        ? "teal"
        : props.entityType === "pj"
        ? "purple"
        : "neutral"
    }
  >
    {props.entityTypeText}
  </Badge>
);

export const DeliveryBlock = (props) => (
  <Badge
    {...props}
    colors={
      props.delivery === "delivery"
        ? "blue"
        : props.delivery === "personal"
        ? "green"
        : "neutral"
    }
  >
    {props.deliveryText}
  </Badge>
);
